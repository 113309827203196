<template>
  <div id="app">
    <base-loader />
    <notification />
    <router-view />
  </div>
</template>

<script>
import { Notification, BaseLoader } from '@/components/base';

export default {
  components: {
    Notification,
    BaseLoader,
  },
};
</script>

<style lang="scss">
@import './index.scss';
</style>
