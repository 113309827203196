export const LOAN_STATUS = {
  ACTIVE: 'Active',
  REPAID: 'Repaid',
  PAST_DUE: 'PastDue',
  LOST: 'Lost',
  AWAITING_DISBURSEMENT: 'AwaitingDisbursement',
  RESTRUCTURED: 'Restructured',
};

export const LOAN_REQUEST_STATUS = {
  CREATED_UNSUBMITTED: 'CreatedUnsubmitted',
  SUBMITTED_FOR_REVIEW: 'SubmittedForReview',
  PRE_APPROVED: 'PreApproved',
  OFFER_ACCEPTED: 'OfferAccepted',
  ACCEPTED_FOR_PROCESSING: 'AcceptedForProcessing',
  OFFER_REJECTED: 'OfferRejected',
  REJECTED: 'Rejected',
  APPROVED: 'Approved',
  EXPIRED: 'Expired',
  ARCHIVED: 'Archived',
  CANCELLED: 'Cancelled',
  CONVERTED: 'Converted',
  RESTRUCTURED: 'Restructured',
};

export const LOAN_REQUEST_TYPE = {
  LPO: 'LocalPurchaseOrder',
  IDF: 'InvoiceDiscountingFacility',
  LEASE_FINANCE: 'LeaseFinance',
  WORKING_CAPITAL: 'WorkingCapital',
  UNKNOWN: 'Unknown',
  OTHERS: 'Others',
};
