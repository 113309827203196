import apiClient from '@/clients/apiClient';

export const getCompanyList = (
  page = 1,
  pageSize = 10,
  search = '',
  isRegistered = '',
  isPlatformMerchant = '',
  status = '',
  startAt = '',
  endAt = '',
  sortBy = '',
  orderBy = ''
) =>
  apiClient.get(
    `businesses?Page=${page}&PageSize=${pageSize}&Search=${search}&IsRegistered=${isRegistered}&IsPlatformMerchant=${isPlatformMerchant}&Status=${status}&CreatedStartAt=${startAt}&CreatedEndAt=${endAt}&Sort.Sort_By=${sortBy}&Sort.Order_By=${orderBy}`
  );

export const getNewCompanies = (page = 1, pageSize = 10, search = '') =>
  apiClient.get(
    `businesses/new?Page=${page}&PageSize=${pageSize}&Search=${search}`
  );

export const getChangedCompanies = (page = 1, pageSize = 10, search = '') =>
  apiClient.get(
    `businesses/changed?Page=${page}&PageSize=${pageSize}&Search=${search}`
  );

export const getCompanyProfile = (id) => apiClient.get(`businesses/${id}`);

export const getCompanyDocuments = (id) =>
  apiClient.get(`businesses/${id}/documents`);

export const uploadCompanyDocument = (payload) =>
  apiClient.post(`businesses/${payload.businessId}/documents`, payload.data);

export const getCompanyLoanRequests = (id) =>
  apiClient.get(`businesses/${id}/loan-requests`);

export const getCompaniesCount = () =>
  apiClient.get('businesses/entities/count');

export const createCompany = (data) => apiClient.post('businesses', data);

export const verifyOrReverifyCompany = (businessProfileId, verificationType) =>
  apiClient.post(`businesses/${businessProfileId}/${verificationType}`, {});

export const getCompanyWallet = (businessId) =>
  apiClient.get(`businesses/${businessId}/wallet`);

export const editCompany = (payload) =>
  apiClient.put(`businesses/${payload.businessId}`, payload.data);

export const updateCompanyLogo = (payload) =>
  apiClient.post(`businesses/${payload.businessId}/logo`, payload.data);

export const getUnpaginatedBusinessList = () =>
  apiClient.get(`businesses/lite`);
