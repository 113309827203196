import apiClient from '@/clients/apiClient';
import { saveAs } from 'file-saver';

export const getLoanList = (
  page = 1,
  pageSize = 10,
  search = '',
  loanType = '',
  loanRequestStatus = '',
  loanStatus = '',
  fromAmount = '',
  toAmount = '',
  maturityStartAt = '',
  maturityEndAt = '',
  createdStartAt = '',
  createdEndAt = '',
  sortBy = '',
  orderBy = ''
) =>
  apiClient.get(
    `loan-requests?Page=${page}&PageSize=${pageSize}&Search=${search}&LoanType=${loanType}&LoanRequestStatus=${loanRequestStatus}&LoanStatus=${loanStatus}&FromAmount=${fromAmount}&ToAmount=${toAmount}&MaturityStartAt=${maturityStartAt}&MaturityEndAt=${maturityEndAt}&CreatedStartAt=${createdStartAt}&CreatedEndAt=${createdEndAt}&Sort.Sort_By=${sortBy}&Sort.Order_By=${orderBy}`
  );

export const getLoansAwaitingAction = (page = 1, pageSize = 10, search = '') =>
  apiClient.get(
    `loan-requests/awaiting-action?Page=${page}&PageSize=${pageSize}&Search=${search}`
  );

export const getLoan = (id) => apiClient.get(`loan-requests/${id}`);

export const getLoanDocuments = (id) =>
  apiClient.get(`loan-requests/${id}/documents`);

export const getLoansSummary = () => apiClient.get(`loans/entities/count`);

export const getLoanRequestLogs = (id) =>
  apiClient.get(`loan-requests/${id}/logs`);

export const addNoteToLoanLog = (id, data) =>
  apiClient.post(`loan-requests/${id}/notes`, data);

export const getLoanTransactions = (loanId) =>
  apiClient.get(`loans/${loanId}/transactions`);

export const getLoanRepayments = (loanId) =>
  apiClient.get(`loans/${loanId}/expected-repayments`);
//loan extension endpoints
export const addLoanExtension = (payload) =>
  apiClient.post(`loans/${payload.loanId}/add-loan-extension`, payload);

export const getExtensionRequest = (loanId, extensionRequestId) =>
  apiClient.get(`loans/${loanId}/extension-request/${extensionRequestId}`);

export const getCFOApproval = (loanId, extensionRequestId) =>
  apiClient.post(`loans/${loanId}/send-to-cfo/${extensionRequestId}`, {});

export const getCROApproval = (loanId, extensionRequestId) =>
  apiClient.post(`loans/${loanId}/pre-approve/${extensionRequestId}`, {});

export const approveLoanExtensionRequest = (loanId, extensionRequestId) =>
  apiClient.post(`loans/${loanId}/approve/${extensionRequestId}`, {});

export const rejectLoanExtensionRequest = (
  loanId,
  extensionRequestId,
  reason
) =>
  apiClient.post(`loans/${loanId}/reject/${extensionRequestId}`, {
    reason,
  });

export const hasPendingExtensionRequest = (loanId) =>
  apiClient.get(`loans/${loanId}/has-pending-request`);

export const retrieveLoanDetailsWithLoanId = (loanId) =>
  apiClient.get(`loan-requests/${loanId}/loans`);

// Loan approval endpoints
export const acceptLoanForProcessing = (loanRequestId) =>
  apiClient.post(`loan-requests/${loanRequestId}/accept-for-processing`, {});

export const declineLoanRequest = (payload) =>
  apiClient.post(`loan-requests/${payload.loanRequestId}/decline`, {
    reason: payload.reason,
  });

export const updateLoanSettings = (payload) =>
  apiClient.put(
    `loan-requests/${payload.loanRequestId}/update-settings`,
    payload.data
  );

export const initiateLoanRestructure = (payload) =>
  apiClient.put(
    `loan-requests/${payload.loanRequestId}/initiate-restructuring`,
    payload.data
  );

export const preApproveLoanRequest = (loanRequestId) =>
  apiClient.post(`loan-requests/${loanRequestId}/pre-approve`, {});

export const sendForCGOApproval = (loanRequestId) =>
  apiClient.post(`loan-requests/${loanRequestId}/send-to-cgo`, {});

export const approveLoanRequest = (loanRequestId) =>
  apiClient.post(`loan-requests/${loanRequestId}/approve`, {});

export const sendForCROApproval = (loanRequestId) =>
  apiClient.post(`loan-requests/${loanRequestId}/send-to-cro`, {});

export const sendForCFOApproval = (loanRequestId) =>
  apiClient.post(`loan-requests/${loanRequestId}/send-to-cfo`, {});

export const makeWalletDisbursement = (payload) =>
  apiClient.post(
    `loans/${payload.loanId}/make-wallet-disbursement`,
    payload.data
  );

export const makeSupplierBankLoanDisbursement = (payload) =>
  apiClient.post(
    `loans/${payload.loanId}/make-supplier-disbursement`,
    payload.data
  );

export const makeCustomerBankLoanDisbursement = (payload) =>
  apiClient.post(
    `loans/${payload.loanId}/make-bank-disbursement`,
    payload.data
  );

export const makeWalletRepayment = (payload) =>
  apiClient.post(`loans/${payload.loanId}/make-wallet-repayment`, payload.data);

export const activateLoan = (payload) =>
  apiClient.post(
    `loans/${payload.childLoanId}/activate-disbursement/${payload.parentLoanId}`
  );

export const makeBankLoanRepayment = (payload) =>
  apiClient.post(`loans/${payload.loanId}/make-bank-repayment`, payload.data);

export const getOtherLoans = (
  page = 1,
  pageSize = 10,
  search = '',
  loanRequestStatus = '',
  createdStartAt = '',
  createdEndAt = '',
  sortBy = '',
  orderBy = ''
) =>
  apiClient.get(
    `loan-requests/others?Page=${page}&PageSize=${pageSize}&Search=${search}&LoanRequestStatus=${loanRequestStatus}&CreatedStartAt=${createdStartAt}&CreatedEndAt=${createdEndAt}&Sort.Sort_By=${sortBy}&Sort.Order_By=${orderBy}`
  );

export const getOtherLoanDetails = (loanRequestId) =>
  apiClient.get(`loan-requests/others/${loanRequestId}`);

export const passAnOtherLoan = (loanRequestId) =>
  apiClient.post(`loan-requests/others/${loanRequestId}/mark-as-converted`);

export const createWCLoanRequest = (payload) =>
  apiClient.post(`loan-requests/working-capital`, payload);

export const createLFLoanRequest = (payload) =>
  apiClient.post(`loan-requests/lease-finance`, payload);

export const createIDFLoanRequest = (payload) =>
  apiClient.post(`loan-requests/idf`, payload);

export const createLPOLoanRequest = (payload) =>
  apiClient.post(`loan-requests/lpo`, payload);

export const resendLoanOffer = (loanRequestId) =>
  apiClient.post(`loan-requests/${loanRequestId}/resend-offer`, {});

export const uploadLoanDocument = (loanRequestId, payload) =>
  apiClient.post(`loan-requests/${loanRequestId}/documents`, payload);

export const submitLoanRequest = (loanRequestId) =>
  apiClient.post(`loan-requests/${loanRequestId}/submit`, {});

export const archiveOrUnarchiveLoanRequest = (loanRequestId, type) =>
  apiClient.post(`loan-requests/${loanRequestId}/${type}`, {});

export const exportLoanTransactionHistory = (EndAt, Format, StartAt, loanId) =>
  apiClient
    .get(
      `loans/${loanId}/transactions/export?EndAt=${EndAt}&Format=${Format}&StartAt=${StartAt}`,
      { responseType: 'blob' }
    )
    .then((response) => {
      let fileFormat = Format === 'Pdf' ? 'pdf' : 'xlsx';
      saveAs(response.data, `Loan-transactions-${EndAt}.${fileFormat}`);
    });
