import {
  LOAN_STATUS,
  LOAN_REQUEST_STATUS,
  LOAN_REQUEST_TYPE,
} from '../constants/loan.js';

export const getStatusDisplayText = (status) => {
  switch (status) {
    case LOAN_STATUS.ACTIVE:
      return 'Active';
    case LOAN_STATUS.LOST:
      return 'Lost';
    case LOAN_STATUS.PAST_DUE:
      return 'Past due';
    case LOAN_REQUEST_STATUS.CREATED_UNSUBMITTED:
      return 'Unsubmitted';
    case LOAN_REQUEST_STATUS.PRE_APPROVED:
      return 'Pre-approved';
    case LOAN_REQUEST_STATUS.SUBMITTED_FOR_REVIEW:
      return 'Submitted';
    case LOAN_REQUEST_STATUS.OFFER_REJECTED:
    case LOAN_REQUEST_STATUS.REJECTED:
      return 'Declined';
    case LOAN_REQUEST_STATUS.OFFER_ACCEPTED:
    case LOAN_REQUEST_STATUS.ACCEPTED_FOR_PROCESSING:
      return 'Processing';
    case LOAN_STATUS.AWAITING_DISBURSEMENT:
      return 'Awaiting disbursement';
    default:
      return status;
  }
};

export const getStatusClasses = (status) => {
  switch (status) {
    case LOAN_REQUEST_STATUS.CREATED_UNSUBMITTED:
    case LOAN_REQUEST_STATUS.SUBMITTED_FOR_REVIEW:
    case LOAN_REQUEST_STATUS.PRE_APPROVED:
    case LOAN_REQUEST_STATUS.OFFER_ACCEPTED:
    case LOAN_REQUEST_STATUS.ACCEPTED_FOR_PROCESSING:
    case LOAN_STATUS.AWAITING_DISBURSEMENT:
      return 'badge-processing';
    case LOAN_REQUEST_STATUS.ARCHIVED:
    case LOAN_REQUEST_STATUS.EXPIRED:
    case LOAN_STATUS.RESTRUCTURED:
      return 'badge-archived-expired';
    case LOAN_REQUEST_STATUS.OFFER_REJECTED:
    case LOAN_REQUEST_STATUS.REJECTED:
    case LOAN_REQUEST_STATUS.CANCELLED:
      return 'badge-declined';
    case LOAN_STATUS.ACTIVE:
      return 'badge-active';
    case LOAN_REQUEST_STATUS.APPROVED:
    case LOAN_REQUEST_STATUS.CONVERTED:
    case LOAN_STATUS.REPAID:
      return 'badge-loan-success';
    case LOAN_STATUS.PAST_DUE:
    case LOAN_STATUS.LOST:
      return 'badge-lost';
    default:
      return 'badge-default';
  }
};

export const getTypeShortText = (loanType) => {
  switch (loanType) {
    case LOAN_REQUEST_TYPE.LPO:
      return 'LPO';
    case LOAN_REQUEST_TYPE.IDF:
      return 'IDF';
    case LOAN_REQUEST_TYPE.LEASE_FINANCE:
      return 'LF';
    case LOAN_REQUEST_TYPE.WORKING_CAPITAL:
      return 'WC';
    case LOAN_REQUEST_TYPE.OTHERS:
      return 'Others';
    default:
      return '--';
  }
};
