import dayjs from 'dayjs';
import router from '../router';
import { resetPassword, logUserOut } from '@/services/authServices';

export default {
  namespaced: true,
  state: () => ({
    currentUser: {},
    expiresAt: null,
    token: null,
  }),
  getters: {
    currentUser: ({ currentUser }) => currentUser,
    isLoggedIn: ({ expiresAt, token }) =>
      !!token && dayjs().isBefore(dayjs(expiresAt)),
    token: ({ token }) => token,
  },
  mutations: {
    SET_CURRENT_USER(state, payload) {
      state.currentUser = payload;
    },
    SET_EXPIRATION(state, payload) {
      state.expiresAt = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
  },
  actions: {
    async logout({ dispatch, commit }, payload) {
      if (payload) {
        try {
          const data = await logUserOut(payload).then(() => {
            commit('SET_CURRENT_USER', {});
            commit('SET_EXPIRATION', null);
            commit('SET_TOKEN', null);

            router.replace({ name: 'Login' });
          });
          return data;
        } catch (error) {
          const { message, statusText } = error;
          dispatch(
            'notifications/displayNotification',
            { message: message ? message : statusText, type: 'danger' },
            { root: true }
          );
        }
      } else {
        commit('SET_CURRENT_USER', {});
        commit('SET_EXPIRATION', null);
        commit('SET_TOKEN', null);

        router.replace({ name: 'Login' });
      }
    },
    async resetPassword({ dispatch }, details) {
      try {
        const data = await resetPassword(details);
        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: `Password reset`, type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
  },
};
